import { isProduction } from '../app-config/environment'

const PDF_REST_API_URL = {
  dev: 'https://pdfservice.dev.surecolabs.com',
  prod: 'https://pdfservice.docday.com'
}

export const getPdfRestApiUrl = () => {
  if (isProduction()) {
    return PDF_REST_API_URL.prod
  }
  return PDF_REST_API_URL.dev
}
