import axios, { AxiosResponse } from 'axios'

import { ActivityEntity } from '@surecompanies/activity-log'
import { getPdfRestApiUrl } from '../api/get-pdf-rest-api-url'


export const logActivity = (payload: ActivityEntity): Promise<AxiosResponse<any> | void> => {
  const baseURL = getPdfRestApiUrl()
  return axios.post(
    'activities',
    {
      timestamp: new Date().toISOString(),
      ...payload
    },
    {
      baseURL,
    }
  ).catch((e) => console.error(e))
}
